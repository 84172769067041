import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Caret from 'components/Icons/Caret';
import RecommendedProductCard from 'components/Others/RecommendedProductCard';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { BackgroundColor, generateAccessoriesImage, generateGlassesImage, generateSparePartImage } from 'utils/image.utils';

const getProductImage = (type: string, color: string, lensColor: string = 'clear', shape: string = 'classic'): string => {

  if (type === 'frame') {
    return generateGlassesImage(color, color, '0', lensColor, BackgroundColor.White, shape);
  }
  else if (type === 'arms') {
    return generateSparePartImage(color, BackgroundColor.White);
  }
  else if (type === 'feltcase') {
    return generateAccessoriesImage(`felt-case-${color}`, BackgroundColor.White);
  }

  return '';
}

const getProductPrice = (region: string, type: string): string => {
  const prices: any = {
    'prescription-glasses': {
      DEFAULT: 'From $100',
      NZ: 'From $100',
      CA: 'From $100',
    },
    'prescription-sunglasses': {
      DEFAULT: 'From $130',
      NZ: 'From $130',
      CA: 'From $130',
    },
    'blue-light-filter-glasses': {
      DEFAULT: '$75',
      NZ: '$75',
      CA: '$75'
    },
    'sunglasses': {
      DEFAULT: '$75',
      NZ: '$75',
      CA: '$75',
    },
    'arms': {
      DEFAULT: '$30',
      NZ: '$30',
      CA: '$30',
    },
    'feltcase': {
      DEFAULT: '$25',
      NZ: '$25',
      CA: '$25',
    },
    'frames': {
      DEFAULT: '$50',
      NZ: '$50',
      CA: '$50',
    },
    'reading-glasses': {
      DEFAULT: '$75',
      NZ: '$75',
      CA: '$75'
    }
  };
  return prices[type]?.[region] || '';
}

const RECOMMENDED_PRODUCTS = [
  {
    title: 'Original - Gingerbread Prescription Glasses',
    image: getProductImage('frame', 'gingerbread'),
    path: '/shop/prescription-glasses/gingerbread',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    }
  },
  {
    title: 'Original - Clear Frost Prescription Glasses',
    image: getProductImage('frame', 'clear-frost'),
    path: '/shop/prescription-glasses/clear-frost',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    }
  },
  {
    visibleLocales: ['international', 'au', 'nz'],
    title: 'Violet Felt Case',
    image: getProductImage('feltcase', 'violet'),
    path: '/shop/accessories/felt-case-violet',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'feltcase'),
      NZ: getProductPrice('NZ', 'feltcase'),
      CA: getProductPrice('CA', 'feltcase'),
    }
  },
  {
    title: 'Original - Black Prescription Sunglasses',
    image: getProductImage('frame', 'black', 'grey'),
    path: '/shop/prescription-sunglasses/black',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-sunglasses'),
      NZ: getProductPrice('NZ', 'prescription-sunglasses'),
      CA: getProductPrice('CA', 'prescription-sunglasses'),
    }
  },
  {
    title: 'Pistachio Arms',
    image: getProductImage('arms', 'pistachio'),
    path: '/shop/spare-parts/pistachio',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'arms'),
      NZ: getProductPrice('NZ', 'arms'),
      CA: getProductPrice('CA', 'arms'),
    }
  },
  {
    title: 'Original - Pink Lemonade Blue Light Filter Glasses',
    image: getProductImage('frame', 'pink-lemonade'),
    path: '/shop/blue-light-filter-glasses/pink-lemonade',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'blue-light-filter-glasses'),
      NZ: getProductPrice('NZ', 'blue-light-filter-glasses'),
      CA: getProductPrice('CA', 'blue-light-filter-glasses'),
    }
  },
  {
    title: 'Orange Felt Case',
    image: getProductImage('feltcase', 'orange'),
    path: '/shop/accessories/felt-case-orange',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'feltcase'),
      NZ: getProductPrice('NZ', 'feltcase'),
      CA: getProductPrice('CA', 'feltcase'),
    }
  },
  {
    title: 'Original - Royal Purple Prescription Glasses',
    image: getProductImage('frame', 'royal-purple'),
    path: '/shop/prescription-glasses/royal-purple',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    }
  },
  {
    title: 'Moss Green Felt Case',
    image: getProductImage('feltcase', 'moss-green'),
    path: '/shop/accessories/felt-case-moss-green',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'feltcase'),
      NZ: getProductPrice('NZ', 'feltcase'),
      CA: getProductPrice('CA', 'feltcase'),
    }
  },
  {
    title: 'Gingerbread Arms',
    image: getProductImage('arms', 'gingerbread'),
    path: '/shop/spare-parts/gingerbread',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'arms'),
      NZ: getProductPrice('NZ', 'arms'),
      CA: getProductPrice('CA', 'arms'),
    }
  },
  {
    title: 'Original - Daffodil Yellow Prescription Sunglasses',
    image: getProductImage('frame', 'daffodil-yellow', 'grey'),
    path: '/shop/prescription-sunglasses/daffodil-yellow',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-sunglasses'),
      NZ: getProductPrice('NZ', 'prescription-sunglasses'),
      CA: getProductPrice('CA', 'prescription-sunglasses'),
    }
  },
  {
    title: 'Original - Raspberry Cordial Blue Light Filter Glasses',
    image: getProductImage('frame', 'raspberry-cordial'),
    path: '/shop/blue-light-filter-glasses/raspberry-cordial',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'blue-light-filter-glasses'),
      NZ: getProductPrice('NZ', 'blue-light-filter-glasses'),
      CA: getProductPrice('CA', 'blue-light-filter-glasses'),
    }
  },
  {
    title: 'Dark Chocolate Arms',
    image: getProductImage('arms', 'dark-chocolate'),
    path: '/shop/spare-parts/dark-chocolate',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'arms'),
      NZ: getProductPrice('NZ', 'arms'),
      CA: getProductPrice('CA', 'arms'),
    }
  },
  {
    title: 'Original - Forest Green Sunglasses',
    image: getProductImage('frame', 'forest-green', 'grey'),
    path: '/shop/sunglasses/forest-green',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'sunglasses'),
      NZ: getProductPrice('NZ', 'sunglasses'),
      CA: getProductPrice('CA', 'sunglasses'),
    }
  },
  {
    title: 'Original - Sky Blue Prescription Glasses',
    image: getProductImage('frame', 'sky-blue'),
    path: '/shop/prescription-glasses/sky-blue',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    }
  },
  {
    title: 'Hot Pink Felt Case',
    image: getProductImage('feltcase', 'hot-pink'),
    path: '/shop/accessories/felt-case-hot-pink',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'feltcase'),
      NZ: getProductPrice('NZ', 'feltcase'),
      CA: getProductPrice('CA', 'feltcase'),
    }
  },
  {
    title: 'Original - Sepia Brown Blue Light Filter Glasses',
    image: getProductImage('frame', 'sepia-brown'),
    path: '/shop/blue-light-filter-glasses/sepia-brown',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'blue-light-filter-glasses'),
      NZ: getProductPrice('NZ', 'blue-light-filter-glasses'),
      CA: getProductPrice('CA', 'blue-light-filter-glasses'),
    }
  },
  {
    title: 'Original - Tangerine Sunglasses',
    image: getProductImage('frame', 'tangerine', 'grey'),
    path: '/shop/sunglasses/tangerine',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'sunglasses'),
      NZ: getProductPrice('NZ', 'sunglasses'),
      CA: getProductPrice('CA', 'sunglasses'),
    }
  },
  {
    visibleLocales: ['international', 'ca'],
    title: 'Blue Felt Case',
    image: getProductImage('feltcase', 'blue'),
    path: '/shop/accessories/felt-case-blue',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'feltcase'),
      NZ: getProductPrice('NZ', 'feltcase'),
      CA: getProductPrice('CA', 'feltcase'),
    }
  },
  {
    title: 'Original - Denim Blue Prescription Glasses',
    image: getProductImage('frame', 'denim-blue'),
    path: '/shop/prescription-glasses/denim-blue',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    }
  },
  {
    title: 'Capote - Denim Blue Prescription Glasses',
    image: getProductImage('frame', 'denim-blue', 'clear', 'round'),
    path: '/shop/prescription-glasses/denim-blue?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    },
    isForcedProduct: true
  },
  {
    title: 'Original - Koala Grey Blue Light Filter Glasses',
    image: getProductImage('frame', 'koala-grey'),
    path: '/shop/blue-light-filter-glasses/koala-grey',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'blue-light-filter-glasses'),
      NZ: getProductPrice('NZ', 'blue-light-filter-glasses'),
      CA: getProductPrice('CA', 'blue-light-filter-glasses'),
    }
  },
  {
    title: 'Capote - Koala Grey Blue Light Filter Glasses',
    image: getProductImage('frame', 'koala-grey', 'clear', 'round'),
    path: '/shop/blue-light-filter-glasses/koala-grey?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'blue-light-filter-glasses'),
      NZ: getProductPrice('NZ', 'blue-light-filter-glasses'),
      CA: getProductPrice('CA', 'blue-light-filter-glasses'),
    },
    isForcedProduct: true
  },
  {
    title: 'Original - Clear Frost Prescription Sunglasses',
    image: getProductImage('frame', 'clear-frost', 'grey'),
    path: '/shop/prescription-sunglasses/clear-frost',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-sunglasses'),
      NZ: getProductPrice('NZ', 'prescription-sunglasses'),
      CA: getProductPrice('CA', 'prescription-sunglasses'),
    }
  },
  {
    title: 'Capote - Clear Frost Prescription Sunglasses',
    image: getProductImage('frame', 'clear-frost', 'grey', 'round'),
    path: '/shop/prescription-sunglasses/clear-frost?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-sunglasses'),
      NZ: getProductPrice('NZ', 'prescription-sunglasses'),
      CA: getProductPrice('CA', 'prescription-sunglasses'),
    },
    isForcedProduct: true
  },
  {
    title: 'Original - Pink Lemonade Sunglasses',
    image: getProductImage('frame', 'pink-lemonade', 'grey'),
    path: '/shop/sunglasses/pink-lemonade',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'sunglasses'),
      NZ: getProductPrice('NZ', 'sunglasses'),
      CA: getProductPrice('CA', 'sunglasses'),
    }
  },
  {
    title: 'Capote - Pink Lemonade Sunglasses',
    image: getProductImage('frame', 'pink-lemonade', 'grey', 'round'),
    path: '/shop/sunglasses/pink-lemonade?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'sunglasses'),
      NZ: getProductPrice('NZ', 'sunglasses'),
      CA: getProductPrice('CA', 'sunglasses'),
    },
    isForcedProduct: true
  },
  {
    title: 'Original - Sepia Brown Reading Glasses',
    image: getProductImage('frame', 'sepia-brown'),
    path: '/shop/reading-glasses/sepia-brown',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'reading-glasses'),
      NZ: getProductPrice('NZ', 'reading-glasses'),
      CA: getProductPrice('CA', 'reading-glasses'),
    }
  },
  {
    title: 'Capote - Sepia Brown Reading Glasses',
    image: getProductImage('frame', 'sepia-brown', 'clear', 'round'),
    path: '/shop/reading-glasses/sepia-brown?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'reading-glasses'),
      NZ: getProductPrice('NZ', 'reading-glasses'),
      CA: getProductPrice('CA', 'reading-glasses'),
    },
    isForcedProduct: true
  },
  {
    title: 'Original - Koala Grey Frames',
    image: getProductImage('frame', 'koala-grey'),
    path: '/shop/frames/koala-grey',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'frames'),
      NZ: getProductPrice('NZ', 'frames'),
      CA: getProductPrice('CA', 'frames'),
    }
  },
  {
    title: 'Capote - Koala Grey Frames',
    image: getProductImage('frame', 'koala-grey', 'clear', 'round'),
    path: '/shop/frames/koala-grey?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'frames'),
      NZ: getProductPrice('NZ', 'frames'),
      CA: getProductPrice('CA', 'frames'),
    },
    isForcedProduct: true
  },
  {
    title: 'Capote - Azure Blue Prescription Glasses',
    image: getProductImage('frame', 'azure-blue', 'clear', 'round'),
    path: '/shop/prescription-glasses/azure-blue?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    },
    isForcedProduct: true
  },
  {
    title: 'Capote - Gingerbread Prescription Glasses',
    image: getProductImage('frame', 'gingerbread', 'clear', 'round'),
    path: '/shop/prescription-glasses/gingerbread?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    },
    isForcedProduct: true
  },
  {
    title: 'Capote - Midnight Blue Prescription Glasses',
    image: getProductImage('frame', 'midnight-blue', 'clear', 'round'),
    path: '/shop/prescription-glasses/midnight-blue?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    },
    isForcedProduct: true
  },
  {
    title: 'Capote - Olive Oil Prescription Glasses',
    image: getProductImage('frame', 'olive-oil', 'clear', 'round'),
    path: '/shop/prescription-glasses/olive-oil?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    },
    isForcedProduct: true
  },
  {
    title: 'Capote - Raspberry Cordial Prescription Glasses',
    image: getProductImage('frame', 'raspberry-cordial', 'clear', 'round'),
    path: '/shop/prescription-glasses/raspberry-cordial?shape=round',
    price: {
      DEFAULT: getProductPrice('DEFAULT', 'prescription-glasses'),
      NZ: getProductPrice('NZ', 'prescription-glasses'),
      CA: getProductPrice('CA', 'prescription-glasses'),
    },
    isForcedProduct: true
  }
];

const DEFAULT_MIN_TABLET_VIEWPORT_WIDTH = 768;

const DEFAULT_MIN_VIEWPORT_WIDTH = 1155;

const SWIPER_BREAKPOINTS = Object.freeze({
  [DEFAULT_MIN_VIEWPORT_WIDTH]: {
    slidesPerGroup: 4,
    slidesPerView: 4,
    spaceBetween: 0,
    speed: 1200
  },
  [DEFAULT_MIN_TABLET_VIEWPORT_WIDTH]: {
    slidesPerGroup: 1,
    slidesPerView: 3,
    spaceBetween: 0,
    speed: 200
  }
});

const HomepageRecommendedProducts = () => {
  const router = useRouter();
  const randomProducts = useMemo(
    () => {
      let recommendedProducts = RECOMMENDED_PRODUCTS.filter(
        (recommendedProduct) =>
          (!recommendedProduct.visibleLocales ||
            recommendedProduct.visibleLocales?.includes(router.locale as string)) && !recommendedProduct.isForcedProduct
      ).sort(() => 0.5 - Math.random()).slice(0, 5)

      const forcedProducts = RECOMMENDED_PRODUCTS.filter(
        (recommendedProduct) => recommendedProduct.isForcedProduct
      );
      const randomForcedProduct = forcedProducts[Math.floor(Math.random() * forcedProducts.length)];

      if (randomForcedProduct) {
        recommendedProducts.unshift(randomForcedProduct);
      }

      return recommendedProducts;
    },
    [router]
  );


  return (
    <section className="bg-white py-[40px] px-0 sm:px-4 sm:pt-[88px] sm:pb-[78px]">

      <div className="mx-auto lg:max-w-[1224px] pt-4">
        <span className="mb-[22px] block text-center text-[24px] font-semibold text-brand-black100 sm:mb-[30px] md:text-[36px]">
          Crowd Picks
        </span>
        <div className="relative mx-auto sm:px-1 lg:max-w-[700px] shop:max-w-[980px] xl:max-w-[1100px] 2xl:max-w-none">
          <Swiper
            className="recommended-products-swiper"
            modules={[Pagination, Navigation]}
            loop={false}
            breakpoints={SWIPER_BREAKPOINTS}
            speed={600}
            navigation={{
              nextEl: '[data-recommended-products-slider-next]',
              prevEl: '[data-recommended-products-slider-previous]'
            }}
            slidesPerView={1.5}
            lazy={true}
            spaceBetween={0}
            watchOverflow={true}
            allowTouchMove={true}
            simulateTouch={false}
            /*loopFillGroupWithBlank={true}*/
            slidesPerGroup={1}
            initialSlide={0}
            key={router.locale}
            pagination={{
              clickable: true,
              el: '[data-recommended-products-slider-pagination]',
              bulletClass: 'review-swiper-bullet',
              bulletActiveClass: '!bg-brand-black100',
              horizontalClass: 'review-swiper-pagination-horizontal',
              renderBullet: (_, classList) => {
                return `<span data-ignore-global-swiper-bullet-style class="review-swiper-bullet cursor-pointer transition-all duration-200 h-[10px] w-[10px] !m-[4px] rounded-full bg-brand-grey500 ${classList}"></span>`;
              }
            }}>
            {randomProducts.map((product) => (
              <SwiperSlide className="!h-auto" key={product.path}>
                <div className="h-full sm:p-[10px]">
                  <RecommendedProductCard {...product} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            data-recommended-products-slider-pagination
            className="mx-auto mt-[30px] flex flex-wrap justify-center"></div>
          <div
            data-recommended-products-slider-previous
            className="transition-color absolute left-[-68px] top-[160px] z-10 hidden h-[42px] w-[42px] rotate-90 transform cursor-pointer items-center justify-center rounded-full bg-white text-brand-black100 shadow-card duration-500 hover:text-brand-orange lg:flex">
            <Caret className="mt-[3px]" fill="currentColor" />
          </div>
          <div
            data-recommended-products-slider-next
            className="rotate absolute right-[-68px] top-[160px] hidden h-[42px] w-[42px]  -rotate-90 transform cursor-pointer items-center justify-center rounded-full bg-white text-brand-black100 shadow-card duration-500 hover:text-brand-orange lg:flex">
            <Caret className="mt-[3px]" fill="currentColor" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageRecommendedProducts;
